import React from 'react';

import styles from './LoginPromo.module.scss';
import { H3, P } from '@components/Skeleton';
import Button from '@skapa/Button';

import { logEvent } from 'utils/analytics/analytics';
import { setLocationHref } from 'utils/window';

import useAuth from 'hooks/useAuth2';
import { useSettings } from 'hooks/useSettings';
import useTranslations from 'hooks/useTranslations';

type LoginPromoProps = {
    showSkeleton: boolean;
};

const LoginPromo: React.FC<LoginPromoProps> = ({ showSkeleton }) => {
    const { loginUrl } = useSettings();
    const { translate } = useTranslations();
    const { isLoggedIn } = useAuth();

    if (isLoggedIn) {
        return null;
    }

    return (
        <div className={styles.loginpromosmall}>
            <H3
                showSkeleton={showSkeleton}
                width={115}
                style={{ marginBottom: 10 }}
            >
                {translate('PROMOTE_LOGIN_TITLE')}
            </H3>
            <P showSkeleton={showSkeleton}>
                {translate('PROMOTE_LOGIN_CONTENT')}
            </P>
            <Button
                text={translate('PROMOTE_LOGIN_BUTTON')}
                type="emphasised"
                loading={showSkeleton}
                style={{ ...(showSkeleton && { width: 170 }) }}
                onClick={() => {
                    setLocationHref(loginUrl);

                    logEvent({
                        category: 'favourites',
                        action: 'login',
                        label: 'favourites',
                    });
                }}
            />
        </div>
    );
};
export default LoginPromo;
