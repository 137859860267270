import { AddToCartSource } from 'apis/cart/operations';
import { AddItemInput } from 'apis/cart/reactQueryHooks';
import { GetListsQuery, RetailItem } from 'apis/list/reactQueryHooks';

import { isAvailableOnline } from 'utils/available-online';

import { PhraseKeys } from 'hooks/useTranslations';

type RetailList = GetListsQuery['lists'][number]['retail'];
type RetailItems = RetailList['items'];

export const getAddAllToCartButtonText = (
    isAllOnlineBuyable: boolean,
    translate: (id: PhraseKeys) => string
) =>
    isAllOnlineBuyable
        ? translate('Add all products to cart')
        : translate('Add all available to cart');

export const isAllItemsOnlineBuyable = (
    list: RetailList,
    enableAlternativeAvailabilityCheck: boolean
) =>
    list.items.every(item =>
        isAvailableOnline(
            item as RetailItem,
            enableAlternativeAvailabilityCheck
        )
    );

export const itemsWithoutPending = (list: RetailList) => list.items;

export const addAvailableItemsToCart = (
    items: RetailItems,
    addToCart: (
        items: { items: Array<AddItemInput> },
        source: AddToCartSource
    ) => Promise<any>,
    onSuccess: () => void,
    enableAlternativeAvailabilityCheck: boolean
) => {
    const itemsToAdd = items
        .filter(item =>
            isAvailableOnline(item, enableAlternativeAvailabilityCheck)
        )
        .map(item => ({
            itemNo: item.itemNo,
            quantity: item.quantity,
        }));

    return addToCart(
        {
            items: itemsToAdd,
        },
        'favourites_add_all'
    ).then(() => onSuccess());
};

export const getUnavailableItemsNames = (
    itemNos: string[],
    list: RetailList
) => {
    return itemNos?.reduce((acc: string[], itemNo: string) => {
        const name = list.items.find(item => item.itemNo === itemNo)?.product
            ?.name;
        return name ? [...acc, name] : acc;
    }, []);
};
