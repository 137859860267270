import React, { FormEvent, useState } from 'react';

import { useRenameList } from 'apis/list/operations';
import { GetListQuery } from 'apis/list/reactQueryHooks';
import useGetLists from 'apis/list/useGetLists';

import { useSettings } from 'hooks/useSettings';
import useTranslations from 'hooks/useTranslations';

import Button from 'skapa/Button';
import FormField from 'skapa/FormField';
import InputField from 'skapa/InputField';
import Modal from 'skapa/Modal';

const RenameList: React.FC<{
    closeModal: () => void;
    onBack: () => void;
    list: GetListQuery['list'];
    isOpen: boolean;
}> = ({ closeModal, onBack, list, isOpen }) => {
    const { maxListNameLength } = useSettings();
    const { translateReplace, translate } = useTranslations();
    const [error, setError] = useState('');
    const [name, setName] = useState('');
    const { lists } = useGetLists();
    const { renameList, isLoading: renameListLoading } = useRenameList();

    const onSubmit = async (event: FormEvent) => {
        event.preventDefault();
        const nameAlreadyExists = !!lists?.find(l => l.name === name);
        if (nameAlreadyExists) {
            setError(translate('NAME_CONFLICT'));
        } else if (name === '') {
            setError(translate('EMPTY_NAME'));
        } else if (name.length > Number(maxListNameLength)) {
            setError(
                translateReplace('MAX_NAME_LENGTH', {
                    length: maxListNameLength?.toString(),
                }).toString()
            );
        } else {
            closeModal();
            await renameList({
                listId: list.listId,
                name,
            });
        }
    };
    return (
        <Modal
            data-testid="rename-list-modal"
            className="rename-list"
            title={translate('Rename list title')}
            handleCloseBtn={closeModal}
            backBtnClick={onBack}
            visible={isOpen}
            footerButtons={[
                <Button
                    key="close"
                    type="primary"
                    fluid
                    text={translate('Rename list confirmation button')}
                    onClick={onSubmit}
                />,
            ]}
        >
            <form onSubmit={onSubmit}>
                <FormField
                    validation={{
                        msg: error,
                        id: 'invalid_list_name',
                    }}
                    shouldValidate={!!error}
                    characterLimit={Number(maxListNameLength)}
                    fieldHelper={{
                        msg: translateReplace('Input field help message', {
                            length: maxListNameLength?.toString(),
                        }),
                    }}
                >
                    <InputField
                        type="text"
                        id="rename-list-input"
                        data-testid="rename-list-form-input"
                        disabled={renameListLoading}
                        defaultValue={
                            list.name
                                ? list.name
                                : translate('Default list name')
                        }
                        onChange={e => {
                            if (error) {
                                setError('');
                            }
                            setName(e.target.value);
                        }}
                    />
                </FormField>
            </form>
        </Modal>
    );
};

export default RenameList;
