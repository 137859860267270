import React, { useEffect, useState } from 'react';

import styles from './Feeds.module.scss';

import useGetLists from 'apis/list/useGetLists';

import { useOptimizely } from 'utils/optimizely/useOptimizely';
import { subscribe } from 'utils/window/pubsub';

import InspirationalFeed from 'components/InspirationalFeed';

type FeedsProps = {
    hideInspirationalFeed?: boolean;
};

const Feeds: React.FunctionComponent<FeedsProps> = ({
    hideInspirationalFeed,
}) => {
    const { currentList } = useGetLists();
    const { trackEvent } = useOptimizely();
    const listView = currentList && currentList.retail;
    const itemList =
        listView?.items && listView?.items.map(item => item.itemNo).join(',');
    const classNames = `${!itemList ? 'noproducts' : ''}`;
    const { isFetched, refetch } = useGetLists();

    useEffect(() => {
        subscribe('FAVOURITE_CLIENT/ADD_TO_FAVOURITES_SUCCESS', function () {
            trackEvent('list_overview_add_to_list');
            refetch();
        });

        subscribe(
            'FAVOURITE_CLIENT/REMOVE_FROM_FAVOURITES_SUCCESS',
            function () {
                trackEvent('list_overview_remove_from_list');
                refetch();
            }
        );
    }, [trackEvent, refetch]);

    const recommendationsProps = {
        'data-strict-filtering': 'true',
        'data-mount-recommendations': 'favourites',
    };
    const [showSkeleton, setShowSkeleton] = useState(true);
    useEffect(() => {
        if (isFetched) {
            setShowSkeleton(false);
        }
    }, [isFetched]);

    if (showSkeleton) {
        return null;
    }
    return (
        <>
            <div
                className={`${styles.recommendations} ${classNames}`}
                data-always-display-add-to-cart-button="true"
            >
                {isFetched ? (
                    <>
                        {!itemList && (
                            <div
                                className="recommendations__noproducts"
                                data-testid="recommendations__noproducts"
                                {...recommendationsProps}
                            />
                        )}
                        {itemList && (
                            <div
                                className="recommendations__products"
                                data-favourites={itemList}
                                data-testid="recommendations__products"
                                {...recommendationsProps}
                            />
                        )}
                    </>
                ) : null}
            </div>
            {!hideInspirationalFeed && <InspirationalFeed />}
        </>
    );
};

export default Feeds;
