import React from 'react';

import documentAddPath from '@ingka/ssr-icon/paths/document-add';

import { useMoveItemsToAnotherList } from 'apis/list/operations';
import {
    GetListsQuery,
    List,
    RetailItemFragment,
    StoreItemFragment,
} from 'apis/list/reactQueryHooks';
import useGetLists from 'apis/list/useGetLists';

import useGetListName from 'hooks/useGetListName';
import useNavigateWithBase from 'hooks/useNavigateWithBase';
import useTranslations from 'hooks/useTranslations';

import Button from 'skapa/Button';
import Choice, { ChoiceItem } from 'skapa/Choice';
import Modal from 'skapa/Modal';

import { useToast } from 'components/Toast';

type MoveToAnotherListProps = {
    items: Array<RetailItemFragment | StoreItemFragment>;
    isOpen: boolean;
    onBack: () => void;
    onClose: () => void;
    onContinue: () => void;
    sourceList: GetListsQuery['lists'][number];
};

const getFirstItemImageURL = (list: GetListsQuery['lists'][number]) => {
    return (
        list.retail.items?.[0]?.product?.images?.find(
            ({ quality }) => quality === 'S5'
        )?.url ?? ''
    );
};

const MoveToAnotherList: React.FC<MoveToAnotherListProps> = ({
    items,
    isOpen,
    onBack,
    onClose,
    onContinue,
    sourceList,
}) => {
    const { translateReplace, translate } = useTranslations();
    const { lists } = useGetLists();
    const { showToast, removeToast } = useToast();
    const { moveItemsToAnotherList, isLoading } = useMoveItemsToAnotherList();
    const getListName = useGetListName();
    const { push } = useNavigateWithBase();
    const currentListName = getListName(sourceList);
    const isMoveSingleItem = items.length === 1;

    if (items.length === 0) {
        return null;
    }

    const handleOnMoveToList = (
        targetList: Pick<List, 'listId' | 'name'>,
        sourceList: Pick<List, 'listId'>
    ) => {
        const listName = getListName(targetList);
        const itemsPayload = items.map(({ itemNo, quantity }) => ({
            itemNo,
            quantity,
        }));
        moveItemsToAnotherList(
            itemsPayload,
            targetList.listId,
            sourceList.listId
        )
            .then(() => {
                const toastName = isMoveSingleItem
                    ? 'item moved to list success'
                    : 'all items moved to list success';
                const toastText = isMoveSingleItem
                    ? `${translateReplace('Item moved to list success', {
                          itemName: items[0].product?.name ?? '',
                          sourceListName: currentListName,
                          destinationListName: listName,
                      })}`
                    : `${translateReplace('All items moved to list success', {
                          sourceListName: currentListName,
                          destinationListName: listName,
                      })}`;
                showToast({
                    name: toastName,
                    text: toastText,
                    actionButtonText: translate('Show'),
                    actionClick: () => {
                        removeToast();
                        push(`${targetList.listId}/retail`);
                    },
                });
                onClose();
            })
            .catch(() => {
                const toastName = isMoveSingleItem
                    ? 'move item to new list error'
                    : 'move all items to list error';
                const toastText = isMoveSingleItem
                    ? `${translateReplace('Move item to a new list error', {
                          itemName: items[0]?.product?.name ?? '',
                          sourceListName: currentListName,
                          destinationListName: listName,
                      })}`
                    : `${translateReplace(
                          'Move all items to a new list error',
                          {
                              sourceListName: currentListName,
                              destinationListName: listName,
                          }
                      )}`;
                showToast({
                    name: toastName,
                    text: toastText,
                });
                onClose();
            });
    };

    return (
        <Modal
            // @ts-ignore
            title={
                isMoveSingleItem
                    ? translateReplace('Move item to list modal title', {
                          itemName: items[0]?.product?.name || '',
                      })
                    : translate('Move all to list modal title')
            }
            handleCloseBtn={onClose}
            backBtnClick={onBack}
            visible={isOpen}
            footerButtons={[
                <Button
                    disabled={isLoading}
                    key="close"
                    type="primary"
                    text={translate('Move to a new list')}
                    onClick={onContinue}
                    iconPosition="leading"
                    ssrIcon={documentAddPath}
                />,
            ]}
        >
            <div>
                <Choice data-testid="move-to-another-list-modal">
                    {(lists || []).map(list => {
                        const listName =
                            list.name || translate('Default list name');
                        return (
                            <ChoiceItem
                                disabled={
                                    list.listId === sourceList.listId ||
                                    isLoading
                                }
                                caption={translateReplace('x items', {
                                    quantity: String(list.quantity),
                                })}
                                key={list.listId}
                                id={list.listId}
                                image={{
                                    src: getFirstItemImageURL(list),
                                    alt: list.name,
                                }}
                                title={listName}
                                onClick={() =>
                                    handleOnMoveToList(list, sourceList)
                                }
                            />
                        );
                    })}
                </Choice>
            </div>
        </Modal>
    );
};

export default MoveToAnotherList;
