import React, { useState } from 'react';

import * as Sentry from '@sentry/react';
import DevModal from '../DevModal';
import ModalPrompt, { ModalFooter, Prompt } from '@ingka/modal';
import arrowRight from '@ingka/ssr-icon/paths/arrow-right';
import arrowUpFromBase from '@ingka/ssr-icon/paths/arrow-up-from-base';
import pencil from '@ingka/ssr-icon/paths/pencil';
import printerSvg from '@ingka/ssr-icon/paths/printer';
import shoppingBagAdd from '@ingka/ssr-icon/paths/shopping-bag-add';
import trashCan from '@ingka/ssr-icon/paths/trash-can';
import wrench from '@ingka/ssr-icon/paths/wrench';

import { useAddToCart } from 'apis/cart/operations';
import { useRemoveItems, useRemoveList } from 'apis/list/operations';
import { GetListQuery, GetListsQuery } from 'apis/list/reactQueryHooks';
import useGetLists from 'apis/list/useGetLists';

import { logRemovedItems } from 'utils/analytics';
import { isAvailableOnline } from 'utils/available-online';
import { useOptimizely } from 'utils/optimizely/useOptimizely';
import { setLocationHref } from 'utils/window';

import { useSettings } from 'hooks/useSettings';
import useShareUrl from 'hooks/useShareUrl';
import useTranslations from 'hooks/useTranslations';

import ActionList, { ActionListItem } from 'skapa/ActionList';
import Button from 'skapa/Button';
import Modal from 'skapa/Modal';

import styles from 'components/List/ListSummary/AddAllToCart/AddAllToCart.module.scss';
import {
    addAvailableItemsToCart,
    getAddAllToCartButtonText,
    getUnavailableItemsNames,
    isAllItemsOnlineBuyable,
} from 'components/List/ListSummary/AddAllToCart/helper';
import CreateNewList from 'components/Modals/CreateNewList';
import MoveToAnotherList from 'components/Modals/MoveToAnotherList';
import RenameList from 'components/Modals/RenameList';
import ShareList from 'components/Modals/ShareList/ShareList';
import { useToast } from 'components/Toast';

const ListMenu: React.FC<{
    onClose: () => void;
    visible: boolean;
    title: string;
    list?: GetListQuery['list'];
    isLandingPage: boolean;
}> = ({ onClose, visible, title, list, isLandingPage }) => {
    const createShareUrl = useShareUrl();
    const {
        hideAddToCart,
        showDevModal,
        cartUrl,
        enableAlternativeAvailabilityCheck,
    } = useSettings();
    const { translate, translateReplace } = useTranslations();
    const [isShareListOpen, setIsShareListOpen] = useState(false);
    const [isRenameListOpen, setIsRenameListOpen] = useState(false);
    const { lists } = useGetLists();
    const { removeItems } = useRemoveItems();
    const { showToast } = useToast();
    const clearAllItemsInList = (
        list: GetListsQuery['lists'][number],
        items: GetListsQuery['lists'][number]['retail']['items']
    ) => {
        showToast({
            name: 'article-removed-toast',
            text: `${translate('Wishlist has been emptied')}`,
            onTimeout: () => {
                removeItems({
                    itemNos: items.map(item => item.itemNo),
                    listId: list.listId,
                }).then(data => {
                    logRemovedItems(items, data.list.listId);
                    return data;
                });
            },
        });
        trackEvent('favourites_page_list_emptied');
    };
    const { removeList } = useRemoveList();
    const [isUnavailableItemsModalOpen, setIsUnavailableItemsModalOpen] =
        useState(false);
    const [unavailableItemsNames, setUnavailableItemsNames] = useState<
        string[]
    >([]);
    const [isMoveToAnotherListOpen, setIsMoveToAnotherListOpen] =
        useState(false);
    const [isCreateListOpen, setIsCreateListOpen] = useState(false);
    const [isDevModalOpen, setIsDevModalOpen] = useState(false);
    const { trackEvent } = useOptimizely();
    const { addToCart, isLoading } = useAddToCart();

    if (!list) {
        return null;
    }

    const shareUrl = createShareUrl(list['retail'].items);

    const onCloseShareList = () => {
        setIsShareListOpen(false);
        onClose();
    };
    const onBackShareList = () => {
        setIsShareListOpen(false);
    };

    const noOfLists = lists?.length ?? 0;
    const noOfItems = list.retail.items.length;

    const containsAvailableItems = list.retail.items.some(i =>
        isAvailableOnline(i, enableAlternativeAvailabilityCheck)
    );

    const onClickAddToCart = async () => {
        try {
            await addAvailableItemsToCart(
                list.retail.items,
                addToCart,
                () => {
                    trackEvent('favourites_add_all_to_cart');
                    showToast({
                        name: 'Add-to-cart-toast',
                        text: translate('Items were added to the cart'),
                        actionButtonText: translate('View cart'),
                        actionClick: () => setLocationHref(cartUrl),
                    });
                },
                enableAlternativeAvailabilityCheck
            );
        } catch (e) {
            setUnavailableItemsNames(
                getUnavailableItemsNames(
                    // @ts-ignore
                    e?.data?.itemNos,
                    list.retail
                )
            );
            setIsUnavailableItemsModalOpen(true);
        }
    };

    const onRemoveList = async () => {
        await removeList({
            listId: list.listId,
        });
        showToast({
            name: 'list-removed-toast',
            text: translateReplace('List was removed', {
                name: list.name || translate('Default list name'),
            }),
        });
        onClose();
    };
    return (
        <>
            <Modal
                data-testid="list-menu-modal"
                className="list-menu"
                title={title}
                handleCloseBtn={onClose}
                visible={visible}
                footerButtons={
                    !hideAddToCart && containsAvailableItems
                        ? [
                              <Button
                                  ssrIcon={shoppingBagAdd}
                                  type="emphasised"
                                  key="add-all-items-to-cart"
                                  loading={isLoading}
                                  text={getAddAllToCartButtonText(
                                      isAllItemsOnlineBuyable(
                                          list.retail,
                                          enableAlternativeAvailabilityCheck
                                      ),
                                      translate
                                  )}
                                  onClick={onClickAddToCart}
                              />,
                          ]
                        : undefined
                }
            >
                <ActionList
                    id="context-menu-actions"
                    data-testid="context-menu-actions"
                >
                    {list.retail.items.length > 0 && (
                        <ActionListItem
                            className="move-all-to-another-list"
                            label={
                                list.retail.items.length === 1
                                    ? translate('Move to another list')
                                    : translate(
                                          'Move all items to another wishlist'
                                      )
                            }
                            onClick={() => setIsMoveToAnotherListOpen(true)}
                            ssrIcon={arrowRight}
                            data-testid="move-all-to-another-list"
                        />
                    )}
                    <ActionListItem
                        label={translate('Rename list label')}
                        onClick={() => setIsRenameListOpen(true)}
                        ssrIcon={pencil}
                    />
                    <ActionListItem
                        className="share-list"
                        label={translate('Share list button label')}
                        onClick={() => {
                            if (navigator.share) {
                                return navigator
                                    .share({
                                        title: translate('Share list title'),
                                        url: shareUrl,
                                    })
                                    .then(() => onClose())
                                    .catch(error => {
                                        Sentry.captureException(
                                            new Error(
                                                `Unable to share list: ${error}`
                                            )
                                        );
                                    });
                            }
                            return setIsShareListOpen(true);
                        }}
                        ssrIcon={arrowUpFromBase}
                        data-testid="share-list"
                    />
                    {!isLandingPage && (
                        <ActionListItem
                            className="print-action"
                            label={translate('Print')}
                            onClick={() => {
                                onClose();
                                if (typeof window !== 'undefined') {
                                    setTimeout(() => window.print(), 500);
                                }
                            }}
                            ssrIcon={printerSvg}
                            data-testid="print-action"
                        />
                    )}
                    {noOfLists > 1 && (
                        <ActionListItem
                            className="delete-list"
                            label={translate('Delete list button label')}
                            onClick={onRemoveList}
                            ssrIcon={trashCan}
                            data-testid="delete-list"
                        />
                    )}
                    {noOfLists === 1 && noOfItems > 0 && (
                        <ActionListItem
                            className="clear-items"
                            label={translate('Empty list button label')}
                            onClick={() => {
                                clearAllItemsInList(list, list.retail.items);
                                onClose();
                            }}
                            ssrIcon={trashCan}
                            data-testid="clear-items"
                        />
                    )}
                    {showDevModal && (
                        <ActionListItem
                            className="dev-modal"
                            label="Dev modal"
                            onClick={() => {
                                setIsDevModalOpen(true);
                            }}
                            ssrIcon={wrench}
                            data-testid="open-dev-modal"
                        />
                    )}
                </ActionList>
            </Modal>
            <ShareList
                isOpen={isShareListOpen}
                onBackShareList={onBackShareList}
                onCloseShareList={onCloseShareList}
                list={list}
            />
            <ModalPrompt
                prefix="list-ingka-"
                className={styles.addAllModal}
                handleCloseBtn={() => setIsUnavailableItemsModalOpen(false)}
                visible={isUnavailableItemsModalOpen}
            >
                <Prompt
                    title={translate('Items not available online')}
                    footer={
                        <ModalFooter>
                            <Button
                                key="close"
                                type="primary"
                                text={translate('modal_close')}
                                onClick={() =>
                                    setIsUnavailableItemsModalOpen(false)
                                }
                            />
                        </ModalFooter>
                    }
                >
                    <ul>
                        {unavailableItemsNames?.map((name, index) => (
                            <li key={index}>{name}</li>
                        ))}
                    </ul>
                    <br />
                    <p>{translate('Items not available online explanation')}</p>
                </Prompt>
            </ModalPrompt>
            <MoveToAnotherList
                items={list.retail.items}
                isOpen={isMoveToAnotherListOpen}
                onBack={() => setIsMoveToAnotherListOpen(false)}
                onClose={() => {
                    onClose();
                    setIsMoveToAnotherListOpen(false);
                }}
                onContinue={() => {
                    setIsMoveToAnotherListOpen(false);
                    setIsCreateListOpen(true);
                }}
                sourceList={list}
            />
            <CreateNewList
                items={list.retail.items}
                isOpen={isCreateListOpen}
                onBack={() => {
                    setIsCreateListOpen(false);
                    setIsMoveToAnotherListOpen(true);
                }}
                onClose={() => {
                    onClose();
                    setIsCreateListOpen(false);
                }}
                sourceList={list}
            />
            <RenameList
                closeModal={() => {
                    onClose();
                    setIsRenameListOpen(false);
                }}
                onBack={() => setIsRenameListOpen(false)}
                list={list}
                isOpen={isRenameListOpen}
            />

            {showDevModal && (
                <DevModal
                    closeModal={() => {
                        onClose();
                        setIsDevModalOpen(false);
                    }}
                    onBack={() => setIsDevModalOpen(false)}
                    list={list}
                    visible={isDevModalOpen}
                />
            )}
        </>
    );
};

export default ListMenu;
