import { handleError, handleSuccess } from '../handlers';
import {
    AddInspirationsMutationVariables,
    useAddInspirationsMutation,
} from '../reactQueryHooks';
import { useQueryClient } from '@tanstack/react-query';

import useMutationHook from 'apis/useMutationHook';

const useAddInspirations = () => {
    const queryClient = useQueryClient();

    const { mutationHandler, isLoading } =
        useMutationHook<AddInspirationsMutationVariables>(
            useAddInspirationsMutation,
            handleSuccess(queryClient),
            handleError
        );

    return {
        isLoading,
        addInspirations: mutationHandler,
    };
};

export default useAddInspirations;
