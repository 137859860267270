import React from 'react';

import IngkaFormField, { FormFieldProps } from '@ingka/form-field';

const FormField = (props: FormFieldProps) => (
    <IngkaFormField prefix="list-ingka-" {...props}>
        {props.children}
    </IngkaFormField>
);

export default FormField;
