import { useCallback } from 'react';

import { List } from 'apis/list/reactQueryHooks';

import useTranslations from 'hooks/useTranslations';

const regex = new RegExp(/^ \((\d+)\)$/);

const useGetListName = () => {
    const { translate } = useTranslations();
    return useCallback(
        (list?: Pick<List, 'name'>) => {
            if (!list) {
                return '';
            }
            const defaultName = translate('Default list name');
            const match = list.name.match(regex);
            if (list.name === '') return defaultName;
            // This name arises when a default list has been copied over from an guest account to a logged in user.
            else if (match && match.length)
                return defaultName + ` (${match[1]})`;
            else return list.name;
        },
        [translate]
    );
};

export default useGetListName;
