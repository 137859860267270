import React from 'react';

import IngkaInputField, { InputFieldProps } from '@ingka/input-field';

const InputField = ({
    children,
    ...props
}: InputFieldProps & { ref?: React.RefObject<HTMLInputElement> }) => (
    <IngkaInputField prefix="list-ingka-" {...props}>
        {children}
    </IngkaInputField>
);

export default InputField;
