import React from 'react';

import * as Sentry from '@sentry/react';
import link from '@ingka/ssr-icon/paths/link';
import mail from '@ingka/ssr-icon/paths/mail';

import { GetListsQuery } from 'apis/list/reactQueryHooks';

import { setLocationHref } from 'utils/window';

import useShareUrl from 'hooks/useShareUrl';
import useTranslations from 'hooks/useTranslations';

import ActionList, { ActionListItem } from 'skapa/ActionList';
import Modal from 'skapa/Modal';

import { useToast } from 'components/Toast';

const ShareList: React.FC<{
    onCloseShareList: () => void;
    onBackShareList: () => void;
    isOpen: boolean;
    list: GetListsQuery['lists'][number];
}> = ({ onCloseShareList, onBackShareList, isOpen, list }) => {
    const { translate, translateReplace } = useTranslations();
    const { showToast } = useToast();
    const createShareUrl = useShareUrl();
    const shareUrl = createShareUrl(list['retail'].items);
    const shareWithLink = () => {
        if (typeof window !== 'undefined') {
            navigator.clipboard
                .writeText(`${window.location.host}${shareUrl}`)
                .then(() => {
                    showToast({
                        name: 'share-list-copy-link',
                        text: `${translate(
                            'Share list by link default copy text'
                        )}`,
                    });
                })
                .catch(error => {
                    showToast({
                        name: 'failed-list-copy-link',
                        text: `${translate('Share list by link fail')}`,
                    });
                    Sentry.captureException(error);
                });
        }
    };

    return (
        <Modal
            title={translate('Share list title')}
            handleCloseBtn={onCloseShareList}
            backBtnClick={onBackShareList}
            visible={isOpen}
        >
            <ActionList id="share-list-menu" data-testid="share-list-menu">
                <ActionListItem
                    key="share-list-link"
                    label={translate('Share list by link label')}
                    onClick={() => {
                        onCloseShareList();
                        shareWithLink();
                    }}
                    ssrIcon={link}
                    data-testid="share-list-link"
                />
                <ActionListItem
                    key="share-list-email"
                    label={translate('Share list by email label')}
                    onClick={() => {
                        onCloseShareList();
                        return setLocationHref(
                            `mailto:?subject=${translate(
                                'Share list by email default subject'
                            )}&body=${translateReplace(
                                'Share list by email default body',
                                {
                                    path: `${
                                        window.location.host
                                    }${encodeURIComponent(shareUrl)}`,
                                }
                            )
                                .toString()
                                .replace(/\n/g, '%0A%0D')}`
                        ); // support line break in email body
                    }}
                    ssrIcon={mail}
                    data-testid="share-list-email"
                />
            </ActionList>
        </Modal>
    );
};

export default ShareList;
