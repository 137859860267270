import React, { ReactNode } from 'react';

import NextLink from 'next/link';

import useLocale from 'hooks/useLocale';

type LinkProps = {
    href: string;
    children?: ReactNode;
};

const Link = ({ children, href, ...restProps }: LinkProps) => {
    const { prefix, language, country } = useLocale();
    return (
        <NextLink
            href={`/${country}/${language}/${prefix}/${href}`}
            as={`/${country}/${language}/${prefix}/${href}`}
            shallow
            prefetch={false}
            {...restProps}
        >
            {children}
        </NextLink>
    );
};

export default Link;
