import React from 'react';

import styles from './LandingContainer.module.scss';

interface LandingContainerProps {
    children: React.ReactNode;
}

const LandingContainer: React.FunctionComponent<LandingContainerProps> = ({
    children,
}) => <div className={`${styles.landingContainer} grid-gap`}>{children}</div>;

export default LandingContainer;
