import useLocale from './useLocale';
import { useRouter } from 'next/router';

const useNavigateWithBase = () => {
    const { prefix, language, country } = useLocale();
    const router = useRouter();
    const base = `${country}/${language}/${prefix}`;

    const replace = (url: string) =>
        router.replace(`/${base}/${url}`, `/${base}/${url}`, {
            scroll: false,
            shallow: true,
        });

    const push = (url: string) =>
        router.push(`/${base}/${url}`, `/${base}/${url}`, {
            scroll: false,
            shallow: true,
        });

    return {
        replace,
        push,
    };
};

export default useNavigateWithBase;
