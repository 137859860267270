import {
    RetailItemFragment,
    StoreItemFragment,
} from 'apis/list/reactQueryHooks';

export const isAvailableOnline = (
    item: StoreItemFragment | RetailItemFragment,
    enableAlternativeAvailabilityCheck: boolean
) => {
    if (enableAlternativeAvailabilityCheck) {
        return item.isCommunicatedOnline;
    }
    return (item as RetailItemFragment).isBuyableOnline;
};
