import React, { useState } from 'react';

import { useCreateList, useMoveItemsToAnotherList } from 'apis/list/operations';
import { GetListsQuery } from 'apis/list/reactQueryHooks';
import useGetLists from 'apis/list/useGetLists';

import { uniqueIdMaker } from 'utils/unique-id-generator.helper';

import useGetListName from 'hooks/useGetListName';
import useNavigateWithBase from 'hooks/useNavigateWithBase';
import { useSettings } from 'hooks/useSettings';
import useTranslations from 'hooks/useTranslations';

import Button from 'skapa/Button';
import FormField from 'skapa/FormField';
import InputField from 'skapa/InputField';
import Modal from 'skapa/Modal';

import { useToast } from 'components/Toast';

type CreateNewListProps = {
    items: GetListsQuery['lists'][number]['retail']['items'];
    isOpen: boolean;
    onBack: () => void;
    onClose: () => void;
    sourceList: Pick<GetListsQuery['lists'][number], 'listId' | 'name'>;
};

const idMaker = uniqueIdMaker('create-new-list-');

const CreateNewList: React.FC<CreateNewListProps> = ({
    items,
    isOpen,
    onBack,
    onClose,
    sourceList,
}) => {
    const { maxListNameLength } = useSettings();
    const { translateReplace, translate } = useTranslations();
    const [error, setError] = useState('');
    const [name, setName] = useState('');
    const { lists } = useGetLists();
    const { moveItemsToAnotherList, isLoading } = useMoveItemsToAnotherList();
    const { createList } = useCreateList();
    const { showToast, removeToast } = useToast();
    const getListName = useGetListName();
    const { push } = useNavigateWithBase();
    const currentListName = getListName(sourceList);
    const id = idMaker.next().value;
    const isMoveSingleItem = items.length === 1;

    if (!lists || items.length === 0) {
        return null;
    }
    const handleClose = () => {
        setName('');
        onClose();
    };

    const handleBack = () => {
        setName('');
        onBack();
    };

    const onSubmit = async () => {
        if (name === '') {
            setError(translate('EMPTY_NAME'));
        } else if (lists.find(l => l.name === name)) {
            setError(translate('NAME_CONFLICT'));
        } else {
            try {
                const itemsPayload = items.map(({ itemNo, quantity }) => ({
                    itemNo,
                    quantity,
                }));
                const targetList = await createList({
                    name,
                });
                await moveItemsToAnotherList(
                    itemsPayload,
                    targetList.list.listId,
                    sourceList.listId
                );
                const toastName = isMoveSingleItem
                    ? 'item moved to list success'
                    : 'all items moved to list success';
                const toastText = isMoveSingleItem
                    ? `${translateReplace('Item moved to list success', {
                          itemName: items[0].product?.name ?? '',
                          sourceListName: currentListName,
                          destinationListName: name,
                      })}`
                    : `${translateReplace('All items moved to list success', {
                          sourceListName: currentListName,
                          destinationListName: name,
                      })}`;
                showToast({
                    name: toastName,
                    text: toastText,
                    actionButtonText: translate('Show'),
                    actionClick: () => {
                        removeToast();
                        push(`${targetList.list.listId}/retail`);
                    },
                });
                setError('');
                handleClose();
            } catch (e) {
                const toastName = isMoveSingleItem
                    ? 'move item to new list error'
                    : 'move all items to list error';
                const toastText = isMoveSingleItem
                    ? `${translateReplace('Move item to a new list error', {
                          itemName: items[0]?.product?.name ?? '',
                          sourceListName: currentListName,
                          destinationListName: name,
                      })}`
                    : `${translateReplace(
                          'Move all items to a new list error',
                          {
                              sourceListName: currentListName,
                              destinationListName: name,
                          }
                      )}`;
                showToast({
                    name: toastName,
                    text: toastText,
                });
                setName('');
                setError('');
                handleClose();
            }
        }
    };

    return (
        <Modal
            title={translate('Create list modal title')}
            handleCloseBtn={handleClose}
            backBtnClick={handleBack}
            visible={isOpen}
            footerButtons={[
                <Button
                    disabled={isLoading}
                    key="close"
                    type="primary"
                    fluid
                    text={translate('Create list confirmation button')}
                    onClick={onSubmit}
                />,
            ]}
        >
            <form onSubmit={onSubmit}>
                <FormField
                    validation={{
                        msg: error,
                        id: 'invalid_list_name',
                    }}
                    shouldValidate={!!error}
                    characterLimit={Number(maxListNameLength)}
                    fieldHelper={{
                        msg: translateReplace('Input field help message', {
                            length: maxListNameLength?.toString(),
                        }),
                    }}
                >
                    <InputField
                        type="text"
                        data-testid="create-new-list-input"
                        id={id}
                        disabled={isLoading}
                        label={translate('Default list name')}
                        value={name}
                        onChange={e => {
                            if (error) {
                                setError('');
                            }
                            setName(e.target.value);
                        }}
                    />
                </FormField>
            </form>
        </Modal>
    );
};

export default CreateNewList;
