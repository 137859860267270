import React from 'react';

import IngkaActionList, {
    ActionListItemProps,
    ActionListProps,
    ActionListItem as IngkaActionListItem,
} from '@ingka/action-list';

const ActionList = ({ children, ...restProps }: ActionListProps) => (
    <IngkaActionList prefix="list-ingka-" {...restProps}>
        {children}
    </IngkaActionList>
);

export const ActionListItem = (props: ActionListItemProps) => (
    <IngkaActionListItem prefix="list-ingka-" {...props} />
);

export default ActionList;
