import React from 'react';

import IngkaModal, {
    ModalProps as AdditionalProps,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Sheets,
} from '@ingka/modal';

type ModalProps = {
    backBtnClick?: () => void;
    children: any;
    floating?: boolean;
    footerButtons?: Array<any>;
    title?: string;
} & AdditionalProps;

const Modal = ({
    backBtnClick,
    children,
    floating,
    footerButtons,
    title,
    ...modalProps
}: ModalProps) => (
    <IngkaModal prefix="list-ingka-" {...modalProps}>
        <Sheets
            prefix="list-ingka-"
            header={
                <ModalHeader
                    prefix="list-ingka-"
                    title={title}
                    floating={floating}
                    backBtnClick={backBtnClick}
                />
            }
            footer={
                footerButtons ? (
                    <ModalFooter prefix="list-ingka-">
                        {footerButtons}
                    </ModalFooter>
                ) : null
            }
        >
            <ModalBody prefix="list-ingka-">{children}</ModalBody>
        </Sheets>
    </IngkaModal>
);

export default Modal;
