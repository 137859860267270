import { useCallback } from 'react';

import { useSettings } from 'hooks/useSettings';

export type Items = {
    itemNo: string;
    quantity: number;
};

const useShareUrl = () => {
    const { shareUrl } = useSettings();
    return useCallback(
        (items: Array<Items>) => {
            const itemsArray = items.map(
                item => `${item.itemNo}:${item.quantity}`
            );
            return `${shareUrl}/${itemsArray}?source=favourites`;
        },
        [shareUrl]
    );
};

export default useShareUrl;
