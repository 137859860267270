import React, { useState } from 'react';

import styles from './DevModal.module.scss';

import useAddInspirations from 'apis/inspiration/hooks/useAddInspirations';
import { useAddItems } from 'apis/list/operations';
import { List } from 'apis/list/reactQueryHooks';

import ActionList, { ActionListItem } from 'skapa/ActionList';
import Button from 'skapa/Button';
import InputField from 'skapa/InputField';
import Modal from 'skapa/Modal';

import { useToast } from 'components/Toast';

const DevModal: React.FC<{
    closeModal: () => void;
    onBack: () => void;
    visible: boolean;
    list: Pick<List, 'listId'>;
}> = ({ closeModal, onBack, visible, list }) => {
    const { addItems } = useAddItems();

    const addItem = ({
        itemNo,
        quantity,
        listId = null,
    }: {
        itemNo: string;
        quantity: number;
        listId?: string | null;
    }) => {
        addItems({
            ignoreUnavailable: true,
            items: {
                itemNo,
                quantity,
            },
            listId,
        });
    };

    const { addInspirations } = useAddInspirations();
    const { showToast } = useToast();
    const [itemNo, setItemNo] = useState('');

    const inspirations = [
        'CE6A0822-DEEF-4AC5-818784CE82632A77',
        'D7E45FF8-FEE9-470F-A8DBDDCA5AF3EFDE',
        '5C8FE330-80CC-4A3E-94138A1AD6E30164',
        '62351E9C-6E64-44F2-97B0830DBA3E9F11',
        'F0348E57-AF00-4E4E-B8765986A4B52EE3',
    ];

    const items = [
        { name: 'BESTA', itemNo: '80294503', quantity: 1, listId: list.listId },
        {
            name: 'FYRTUR',
            itemNo: '10408206',
            quantity: 1,
            listId: list.listId,
        },
        {
            name: 'PYSSLA',
            itemNo: '50128572',
            quantity: 1,
            listId: list.listId,
        },
        { name: 'VEDBO', itemNo: '40423583', quantity: 1, listId: list.listId },
        {
            name: 'VEDDINGE',
            itemNo: '20205430',
            quantity: 1,
            listId: list.listId,
        },
    ];

    const handleAddItem = () => {
        try {
            addItem({
                itemNo,
                quantity: 1,
                listId: list.listId,
            });
            showToast({
                name: 'add-item-dev-modal',
                text: `Successfully added item ${itemNo}`,
            });
        } catch (e) {
            showToast({
                name: 'failed-add-item-dev-modal',
                text: `Failed to add item ${itemNo}`,
            });
        }
    };
    return (
        <Modal
            data-testid="dev-modal"
            className="dev-menu"
            title="Helper modal for devs"
            handleCloseBtn={closeModal}
            backBtnClick={onBack}
            visible={visible}
        >
            <h1>Dev modal</h1>
            <h2>Add items</h2>
            <ActionList id="item-menu-actions" data-testid="item-menu-actions">
                {items.map(item => (
                    <ActionListItem
                        key={item.name}
                        label={`${item.name}`}
                        onClick={() => {
                            addItem(item);
                            closeModal();
                        }}
                    />
                ))}
            </ActionList>
            <InputField
                className={styles.addItemField}
                type="text"
                id="add-by-item-number"
                data-testid="add-by-item-number-field"
                onChange={e => setItemNo(e.target.value)}
                value={itemNo}
                label={'Add by item number:'}
            />
            <Button
                className={styles.addItemButton}
                type="primary"
                small
                text={'Add item'}
                onClick={handleAddItem}
            />
            <h2>Add inspirations</h2>
            <ActionList id="item-menu-actions" data-testid="item-menu-actions">
                {inspirations.map((item, i) => (
                    <ActionListItem
                        key={i}
                        label={`Inspiration ${i + 1}`}
                        onClick={() => {
                            addInspirations({ ids: [item] });
                            closeModal();
                        }}
                    />
                ))}
            </ActionList>
        </Modal>
    );
};

export default DevModal;
