import React, { useEffect } from 'react';

import useTranslations from 'hooks/useTranslations';

const InspirationalFeed: React.FC = () => {
    const { translate } = useTranslations();
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.ikea?.organicFeed?.init();
        }
    }, []);
    return (
        <div data-mount-feed data-title={translate('Inspiration feed title')} />
    );
};
export default InspirationalFeed;
